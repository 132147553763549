import React, { useState, useEffect } from "react";
import {
  Mail,
  UserPlus,
  Loader2,
  CheckCircle,
  XCircle,
  User,
  Eye,
} from "lucide-react";
import { supabase } from "../lib/supabase";
import { useProfile } from "../contexts/ProfileContext";
import { useFamily } from "../contexts/FamilyContext";
import MemberBioModal from "./MemberBioModal";

type Member = {
  profile: {
    id: string;
    full_name: string;
    avatar_url: string | null;
    bio: string | null;
  };
  role: string;
};

type Invitation = {
  id: string;
  email: string;
  status: string;
  created_at: string;
  expires_at: string;
};

export default function FamilyMembersView() {
  const { profile } = useProfile();
  const { currentFamily } = useFamily();
  const [members, setMembers] = useState<Member[]>([]);
  const [invitations, setInvitations] = useState<Invitation[]>([]);
  const [loading, setLoading] = useState(true);
  const [inviting, setInviting] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [selectedMember, setSelectedMember] = useState<Member | null>(null);

  useEffect(() => {
    if (currentFamily) {
      loadFamilyData();
    }
  }, [currentFamily]);

  const loadFamilyData = async () => {
    if (!currentFamily) return;

    try {
      await Promise.all([loadMembers(), loadInvitations()]);
    } catch (err: any) {
      console.error("Error loading family data:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const loadMembers = async () => {
    if (!currentFamily) return;

    try {
      const { data, error } = await supabase
        .from("family_members")
        .select(
          `
          profile:profiles (
            id,
            full_name,
            avatar_url,
            bio
          ),
          role
        `
        )
        .eq("family_id", currentFamily.id);

      if (error) throw error;
      setMembers(data || []);
    } catch (err: any) {
      console.error("Error loading members:", err);
      setError(err.message);
    }
  };

  const loadInvitations = async () => {
    if (!currentFamily) return;

    try {
      const { data, error } = await supabase
        .from("invitations")
        .select("*")
        .eq("family_id", currentFamily.id)
        .order("created_at", { ascending: false });

      if (error) throw error;
      setInvitations(data || []);
    } catch (err: any) {
      console.error("Error loading invitations:", err);
    }
  };

  const handleInvite = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentFamily || !profile) return;

    setError(null);
    setSuccess(null);
    setInviting(true);

    try {
      // Check for existing invitation
      const { data: existingInvite, error: checkError } = await supabase
        .from("invitations")
        .select("*")
        .eq("email", email)
        .eq("family_id", currentFamily.id)
        .maybeSingle();

      if (existingInvite) {
        setError("An invitation has already been sent to this email");
        return;
      }

      // Calculate expiration date (7 days from now)
      const expiresAt = new Date();
      expiresAt.setDate(expiresAt.getDate() + 7);

      // Create the invitation record
      const { data: invitation, error: inviteError } = await supabase
        .from("invitations")
        .insert({
          family_id: currentFamily.id,
          email,
          invited_by: profile.id,
          status: "pending",
          expires_at: expiresAt.toISOString(),
        })
        .select()
        .single();

      if (inviteError) throw inviteError;

      // Call the Edge Function to send the email
      const { data, error: emailError } = await supabase.functions.invoke(
        "send-invitation",
        {
          body: {
            invitation_id: invitation.id,
            email,
            family_id: currentFamily.id,
            family_name: currentFamily.name,
            expires_at: expiresAt.toISOString(),
          },
        }
      );

      if (emailError) {
        // Cleanup the invitation if email fails
        await supabase.from("invitations").delete().eq("id", invitation.id);
        throw emailError;
      }

      setSuccess("Invitation sent successfully!");
      setEmail("");
      await loadInvitations();
    } catch (err: any) {
      console.error("Error sending invitation:", err);
      setError(err.message || "Failed to send invitation");
    } finally {
      setInviting(false);
    }
  };

  if (!currentFamily) {
    return (
      <div className="text-center py-8">
        <h2 className="text-xl font-semibold text-sepia-800 mb-4">
          Select a Family
        </h2>
        <p className="text-sepia-600">
          Please select or create a family to manage members.
        </p>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-sepia-600" />
      </div>
    );
  }

  return (
    <div>
      <div className="mb-8">
        <h2 className="text-2xl font-serif font-bold text-sepia-900 mb-6">
          {currentFamily.name} - Family Members
        </h2>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-lg font-semibold text-sepia-800 mb-4">
            Invite New Member
          </h3>

          <form onSubmit={handleInvite} className="space-y-4">
            {error && (
              <div className="p-4 bg-red-50 border border-red-200 rounded-md text-red-700">
                {error}
              </div>
            )}

            {success && (
              <div className="p-4 bg-green-50 border border-green-200 rounded-md text-green-700">
                {success}
              </div>
            )}

            <div className="flex gap-4">
              <div className="flex-1">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter email address"
                  className="w-full px-4 py-2 border border-sepia-200 rounded-md focus:outline-none focus:ring-2 focus:ring-sepia-500"
                  required
                />
              </div>
              <button
                type="submit"
                disabled={inviting}
                className="px-4 py-2 bg-sepia-700 text-sepia-50 rounded-md hover:bg-sepia-800 disabled:opacity-50 flex items-center"
              >
                {inviting ? (
                  <>
                    <Loader2 className="animate-spin -ml-1 mr-2 h-4 w-4" />
                    Sending...
                  </>
                ) : (
                  <>
                    <UserPlus className="w-4 h-4 mr-2" />
                    Invite
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-8">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-lg font-semibold text-sepia-800 mb-4">
            Current Members
          </h3>

          <div className="space-y-4">
            {members.map((member) => (
              <div
                key={member.profile.id}
                className="flex items-center justify-between p-4 bg-sepia-50 rounded-md"
              >
                <div className="flex items-center">
                  <div className="w-10 h-10 bg-sepia-200 rounded-full flex items-center justify-center overflow-hidden">
                    {member.profile.avatar_url ? (
                      <img
                        src={member.profile.avatar_url}
                        alt={member.profile.full_name}
                        className="w-10 h-10 object-cover"
                      />
                    ) : (
                      <User className="w-5 h-5 text-sepia-600" />
                    )}
                  </div>
                  <div className="ml-3">
                    <div className="font-medium text-sepia-900">
                      {member.profile.full_name}
                    </div>
                    <div className="text-sm text-sepia-600 capitalize">
                      {member.role}
                    </div>
                  </div>
                </div>
                <button
                  onClick={() => setSelectedMember(member)}
                  className="flex items-center px-3 py-1 text-sm text-sepia-600 hover:text-sepia-800 hover:bg-sepia-100 rounded-md transition-colors"
                >
                  <Eye className="w-4 h-4 mr-1" />
                  View Bio
                </button>
              </div>
            ))}

            {members.length === 0 && (
              <div className="text-center py-8 text-sepia-600">
                No members yet
              </div>
            )}
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-lg font-semibold text-sepia-800 mb-4">
            Pending Invitations
          </h3>

          <div className="space-y-4">
            {invitations.map((invitation) => (
              <div
                key={invitation.id}
                className="flex items-center justify-between p-4 bg-sepia-50 rounded-md"
              >
                <div className="flex items-center">
                  <Mail className="w-5 h-5 text-sepia-600" />
                  <div className="ml-3">
                    <div className="font-medium text-sepia-900">
                      {invitation.email}
                    </div>
                    <div className="text-sm text-sepia-600">
                      Sent{" "}
                      {new Date(invitation.created_at).toLocaleDateString()}
                      <br />
                      Expires{" "}
                      {new Date(invitation.expires_at).toLocaleDateString()}
                    </div>
                  </div>
                </div>
                {invitation.status === "accepted" ? (
                  <CheckCircle className="w-5 h-5 text-green-500" />
                ) : invitation.status === "rejected" ? (
                  <XCircle className="w-5 h-5 text-red-500" />
                ) : (
                  <span className="text-sm text-sepia-600">Pending</span>
                )}
              </div>
            ))}

            {invitations.length === 0 && (
              <div className="text-center py-8 text-sepia-600">
                No pending invitations
              </div>
            )}
          </div>
        </div>
      </div>

      {selectedMember && (
        <MemberBioModal
          member={selectedMember}
          onClose={() => setSelectedMember(null)}
        />
      )}
    </div>
  );
}
