import React, { useState, useEffect } from 'react';
import { FolderPlus, Folder, Edit2, Trash2, Loader2 } from 'lucide-react';
import { supabase } from '../lib/supabase';
import DeleteConfirmationModal from './DeleteConfirmationModal';

type Collection = {
  id: string;
  name: string;
  description: string | null;
};

type CollectionSelectorProps = {
  familyId: string;
  selectedCollection: string | null;
  onCollectionChange: (collectionId: string | null) => void;
};

export default function CollectionSelector({ 
  familyId, 
  selectedCollection,
  onCollectionChange 
}: CollectionSelectorProps) {
  const [collections, setCollections] = useState<Collection[]>([]);
  const [showNewForm, setShowNewForm] = useState(false);
  const [newName, setNewName] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [editingCollection, setEditingCollection] = useState<Collection | null>(null);
  const [deletingCollection, setDeletingCollection] = useState<Collection | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    loadCollections();
  }, [familyId]);

  const loadCollections = async () => {
    const { data, error } = await supabase
      .from('story_collections')
      .select('*')
      .eq('family_id', familyId)
      .order('name');

    if (error) {
      console.error('Error loading collections:', error);
      return;
    }

    setCollections(data || []);
  };

  const handleCreateCollection = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const { error } = await supabase
      .from('story_collections')
      .insert({
        family_id: familyId,
        name: newName,
        description: newDescription || null
      });

    if (error) {
      console.error('Error creating collection:', error);
      return;
    }

    setNewName('');
    setNewDescription('');
    setShowNewForm(false);
    loadCollections();
  };

  const handleUpdateCollection = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!editingCollection) return;

    const { error } = await supabase
      .from('story_collections')
      .update({
        name: newName,
        description: newDescription
      })
      .eq('id', editingCollection.id);

    if (error) {
      console.error('Error updating collection:', error);
      return;
    }

    setNewName('');
    setNewDescription('');
    setEditingCollection(null);
    setShowNewForm(false);
    loadCollections();
  };

  const handleDeleteCollection = async () => {
    if (!deletingCollection) return;
    setIsDeleting(true);

    try {
      const { error } = await supabase
        .from('story_collections')
        .delete()
        .eq('id', deletingCollection.id);

      if (error) throw error;

      if (selectedCollection === deletingCollection.id) {
        onCollectionChange(null);
      }

      loadCollections();
    } catch (err) {
      console.error('Error deleting collection:', err);
    } finally {
      setIsDeleting(false);
      setDeletingCollection(null);
    }
  };

  const startEditing = (collection: Collection) => {
    setEditingCollection(collection);
    setNewName(collection.name);
    setNewDescription(collection.description || '');
    setShowNewForm(true);
  };

  return (
    <div className="mb-6">
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4 mb-4">
        <h3 className="text-lg font-semibold text-sepia-800">Collections</h3>
        <button
          onClick={() => {
            setEditingCollection(null);
            setNewName('');
            setNewDescription('');
            setShowNewForm(!showNewForm);
          }}
          className="flex items-center px-3 py-1 text-sm text-sepia-600 hover:text-sepia-800 hover:bg-sepia-100 rounded-md transition-colors w-full sm:w-auto justify-center sm:justify-start"
        >
          <FolderPlus className="w-4 h-4 mr-1" />
          New Collection
        </button>
      </div>

      {showNewForm && (
        <form onSubmit={editingCollection ? handleUpdateCollection : handleCreateCollection} className="mb-4 p-4 bg-white rounded-md shadow-sm">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-sepia-700 mb-1">
                Collection Name
              </label>
              <input
                type="text"
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                className="w-full px-3 py-2 border border-sepia-300 rounded-md"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-sepia-700 mb-1">
                Description (Optional)
              </label>
              <input
                type="text"
                value={newDescription}
                onChange={(e) => setNewDescription(e.target.value)}
                className="w-full px-3 py-2 border border-sepia-300 rounded-md"
              />
            </div>
            <div className="flex justify-end space-x-2">
              <button
                type="button"
                onClick={() => {
                  setShowNewForm(false);
                  setEditingCollection(null);
                }}
                className="px-3 py-1 text-sm text-sepia-600 hover:text-sepia-800"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-3 py-1 text-sm bg-sepia-700 text-sepia-50 rounded-md hover:bg-sepia-800"
              >
                {editingCollection ? 'Update' : 'Create'}
              </button>
            </div>
          </div>
        </form>
      )}

      <div className="flex flex-wrap gap-2">
        <button
          onClick={() => onCollectionChange(null)}
          className={`flex items-center px-3 py-1.5 rounded-md text-sm w-full sm:w-auto justify-center sm:justify-start ${
            selectedCollection === null
              ? 'bg-sepia-700 text-sepia-50'
              : 'bg-sepia-100 text-sepia-700 hover:bg-sepia-200'
          }`}
        >
          All Stories
        </button>
        {collections.map((collection) => (
          <div key={collection.id} className="flex items-center w-full sm:w-auto">
            <button
              onClick={() => onCollectionChange(collection.id)}
              className={`flex items-center px-3 py-1.5 rounded-l-md text-sm flex-1 sm:flex-none justify-center sm:justify-start ${
                selectedCollection === collection.id
                  ? 'bg-sepia-700 text-sepia-50'
                  : 'bg-sepia-100 text-sepia-700 hover:bg-sepia-200'
              }`}
            >
              <Folder className="w-4 h-4 mr-1" />
              {collection.name}
            </button>
            <div className={`flex rounded-r-md overflow-hidden border-l ${
              selectedCollection === collection.id
                ? 'border-sepia-600 bg-sepia-700'
                : 'border-sepia-200 bg-sepia-100'
            }`}>
              <button
                onClick={() => startEditing(collection)}
                className={`p-1.5 hover:bg-sepia-200 ${
                  selectedCollection === collection.id
                    ? 'text-sepia-50 hover:bg-sepia-600'
                    : 'text-sepia-700'
                }`}
                title="Edit collection"
              >
                <Edit2 className="w-4 h-4" />
              </button>
              <button
                onClick={() => setDeletingCollection(collection)}
                className={`p-1.5 hover:bg-sepia-200 ${
                  selectedCollection === collection.id
                    ? 'text-sepia-50 hover:bg-sepia-600'
                    : 'text-sepia-700'
                }`}
                title="Delete collection"
              >
                <Trash2 className="w-4 h-4" />
              </button>
            </div>
          </div>
        ))}
      </div>

      {deletingCollection && (
        <DeleteConfirmationModal
          title="Delete Collection"
          message={`Are you sure you want to delete "${deletingCollection.name}"? Stories in this collection will be preserved but will no longer be grouped together.`}
          onConfirm={handleDeleteCollection}
          onCancel={() => setDeletingCollection(null)}
          isDeleting={isDeleting}
        />
      )}
    </div>
  );
}