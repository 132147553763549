import { Navigate, useSearchParams } from "react-router-dom";
import AuthUI from "../components/AuthUI";
import { useAuth } from "../contexts/AuthContext";

export default function AuthPage() {
  const { user, loading } = useAuth();
  const [searchParams] = useSearchParams();
  const invitationId = searchParams.get("invitation");

  // If we're still verifying auth state, show nothing
  if (loading) {
    return null;
  }

  // If user is logged in and there's no invitation, just go to dashboard
  if (user && !invitationId) {
    return <Navigate to="/dashboard" replace />;
  }

  // Otherwise, show the AuthUI
  return (
    <div className="min-h-screen relative">
      {/* Grid of family-themed images */}
      <div className="absolute inset-0 grid grid-cols-2 md:grid-cols-3 gap-1">
        <div className="relative">
          <img
            src="https://images.unsplash.com/photo-1609220136736-443140cffec6"
            alt="Family heritage"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-sepia-900/40"></div>
        </div>
        <div className="relative hidden md:block">
          <img
            src="https://images.unsplash.com/photo-1533659828870-95ee305cee3e"
            alt="Vintage memories"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-sepia-900/40"></div>
        </div>
        <div className="relative">
          <img
            src="https://images.unsplash.com/photo-1511895426328-dc8714191300"
            alt="Family gathering"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-sepia-900/40"></div>
        </div>
      </div>

      {/* Overlay gradient */}
      <div className="absolute inset-0 bg-gradient-to-b from-sepia-900/70 via-sepia-900/50 to-sepia-900/70"></div>

      {/* Auth UI */}
      <div className="relative min-h-screen flex items-center justify-center px-4">
        <AuthUI />
      </div>
    </div>
  );
}
