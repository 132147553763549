import React from 'react';
import { AlertTriangle, Loader2 } from 'lucide-react';

type DeleteConfirmationModalProps = {
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
  isDeleting?: boolean;
};

export default function DeleteConfirmationModal({
  title,
  message,
  onConfirm,
  onCancel,
  isDeleting = false
}: DeleteConfirmationModalProps) {
  return (
    <div className="fixed inset-0 bg-sepia-900/50 backdrop-blur-sm flex items-center justify-center p-4 z-[60]">
      <div className="bg-sepia-50 rounded-lg shadow-xl w-full max-w-md">
        <div className="p-6">
          <div className="flex items-center mb-4">
            <div className="w-10 h-10 rounded-full bg-red-100 flex items-center justify-center">
              <AlertTriangle className="w-6 h-6 text-red-600" />
            </div>
            <h2 className="ml-3 text-xl font-serif font-bold text-sepia-900">{title}</h2>
          </div>
          
          <p className="text-sepia-700 mb-6">{message}</p>
          
          <div className="flex justify-end space-x-3">
            <button
              onClick={onCancel}
              disabled={isDeleting}
              className="px-4 py-2 text-sm font-medium text-sepia-700 hover:text-sepia-800 disabled:opacity-50"
            >
              Cancel
            </button>
            <button
              onClick={onConfirm}
              disabled={isDeleting}
              className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 disabled:opacity-50 flex items-center"
            >
              {isDeleting ? (
                <>
                  <Loader2 className="animate-spin -ml-1 mr-2 h-4 w-4" />
                  Deleting...
                </>
              ) : (
                'Delete'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}