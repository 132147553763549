import React, { useState, useEffect, useRef } from 'react';
import { X, Calendar, User, Folder, Edit2, Trash2, ChevronLeft, ChevronRight, Loader2 } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useAuth } from '../contexts/AuthContext';
import DeleteConfirmationModal from './DeleteConfirmationModal';

type Story = {
  id: string;
  title: string;
  content: string;
  images: string[];
  date_of_event: string | null;
  created_at: string;
  author: {
    id: string;
    full_name: string;
  } | null;
  collection: {
    name: string;
  } | null;
};

type StoryViewerProps = {
  story: Story;
  onClose: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
};

export default function StoryViewer({ story, onClose, onEdit, onDelete }: StoryViewerProps) {
  const { user } = useAuth();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const isAuthor = user?.id === story.author?.id;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  const handleDelete = async () => {
    if (!isAuthor) return;
    setDeleting(true);

    try {
      // Delete the story
      const { error } = await supabase
        .from('stories')
        .delete()
        .eq('id', story.id);

      if (error) throw error;

      // Delete associated images if they exist
      if (story.images?.length > 0) {
        const imagePaths = story.images.map(url => url.split('/').pop()).filter(Boolean);
        if (imagePaths.length > 0) {
          await supabase.storage
            .from('story-images')
            .remove(imagePaths);
        }
      }

      onDelete?.();
    } catch (err) {
      console.error('Error deleting story:', err);
    } finally {
      setDeleting(false);
      setShowDeleteConfirm(false);
    }
  };

  const previousImage = () => {
    setCurrentImageIndex(current => 
      current === 0 ? story.images.length - 1 : current - 1
    );
  };

  const nextImage = () => {
    setCurrentImageIndex(current => 
      current === story.images.length - 1 ? 0 : current + 1
    );
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  return (
    <div className="fixed inset-0 bg-sepia-900/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div 
        ref={modalRef} 
        className={`bg-sepia-50 rounded-lg shadow-xl w-full max-w-4xl ${
          isFullscreen ? 'fixed inset-0 rounded-none' : 'max-h-[90vh]'
        } overflow-hidden`}
      >
        <div className="p-6 border-b border-sepia-200 flex justify-between items-center">
          <h2 className="text-2xl font-serif font-bold text-sepia-900">{story.title}</h2>
          <div className="flex items-center space-x-2">
            {isAuthor && (
              <>
                <button
                  onClick={onEdit}
                  className="p-2 text-sepia-600 hover:text-sepia-800 hover:bg-sepia-100 rounded-full transition-colors"
                  title="Edit story"
                >
                  <Edit2 className="w-5 h-5" />
                </button>
                <button
                  onClick={() => setShowDeleteConfirm(true)}
                  className="p-2 text-red-600 hover:text-red-800 hover:bg-red-100 rounded-full transition-colors"
                  title="Delete story"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
              </>
            )}
            <button
              onClick={onClose}
              className="p-2 text-sepia-500 hover:text-sepia-700 hover:bg-sepia-100 rounded-full transition-colors"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>

        <div className={`${isFullscreen ? 'h-[calc(100vh-129px)]' : 'max-h-[calc(90vh-129px)]'} overflow-y-auto`}>
          {story.images?.length > 0 && (
            <div className="relative">
              <div 
                className={`relative ${
                  isFullscreen 
                    ? 'h-[calc(100vh-200px)]' 
                    : 'h-[50vh] sm:h-[60vh] md:h-[70vh]'
                } bg-sepia-100`}
              >
                <img
                  src={story.images[currentImageIndex]}
                  alt={`${story.title} - Image ${currentImageIndex + 1}`}
                  className="w-full h-full object-contain cursor-pointer"
                  onClick={toggleFullscreen}
                />
                
                {story.images.length > 1 && (
                  <>
                    <button
                      onClick={previousImage}
                      className="absolute left-2 sm:left-4 top-1/2 -translate-y-1/2 p-2 sm:p-4 rounded-full bg-sepia-900/50 text-sepia-50 hover:bg-sepia-900/70 transition-colors"
                    >
                      <ChevronLeft className="w-6 h-6 sm:w-8 sm:h-8" />
                    </button>
                    <button
                      onClick={nextImage}
                      className="absolute right-2 sm:right-4 top-1/2 -translate-y-1/2 p-2 sm:p-4 rounded-full bg-sepia-900/50 text-sepia-50 hover:bg-sepia-900/70 transition-colors"
                    >
                      <ChevronRight className="w-6 h-6 sm:w-8 sm:h-8" />
                    </button>
                    <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex space-x-2">
                      {story.images.map((_, index) => (
                        <button
                          key={index}
                          onClick={() => setCurrentImageIndex(index)}
                          className={`w-2.5 h-2.5 rounded-full transition-colors ${
                            index === currentImageIndex
                              ? 'bg-sepia-50'
                              : 'bg-sepia-50/50 hover:bg-sepia-50/75'
                          }`}
                        />
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}

          <div className="p-6">
            <div className="flex flex-wrap gap-2 mb-6 text-sm text-sepia-600">
              {story.collection && (
                <div className="flex items-center">
                  <Folder className="w-4 h-4 mr-1" />
                  <span>{story.collection.name}</span>
                </div>
              )}
              {story.date_of_event && (
                <div className="flex items-center">
                  <Calendar className="w-4 h-4 mr-1" />
                  <span>{new Date(story.date_of_event).toLocaleDateString()}</span>
                </div>
              )}
              <div className="flex items-center">
                <User className="w-4 h-4 mr-1" />
                <span>{story.author?.full_name || 'Unknown'}</span>
              </div>
            </div>

            <div 
              className="prose max-w-none"
              dangerouslySetInnerHTML={{ __html: story.content }}
            />
          </div>
        </div>
      </div>

      {showDeleteConfirm && (
        <DeleteConfirmationModal
          title="Delete Story"
          message="Are you sure you want to delete this story? This action cannot be undone."
          onConfirm={handleDelete}
          onCancel={() => setShowDeleteConfirm(false)}
          isDeleting={deleting}
        />
      )}
    </div>
  );
}