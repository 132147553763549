// ============ IMPORTS ============
import React from "react";
import { ChevronRight, BookOpen, Users, Shield } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

// ============ LANDINGPAGE COMPONENT ============
export default function LandingPage() {
  const navigate = useNavigate();
  const { user } = useAuth();

  // Redirect if user already logged in
  React.useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  const navigateToSignUp = () => {
    navigate("/auth", { state: { isSignUp: true } });
  };

  const navigateToSignIn = () => {
    navigate("/auth");
  };

  return (
    <div className="relative min-h-screen bg-sepia-50">
      {/* ============ NAVIGATION ============ */}
      <nav className="fixed w-full z-10 bg-gradient-to-b from-sepia-900/80 to-transparent">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center py-4">
            <div className="flex items-center space-x-2">
              <img
                src="https://pregs.blob.core.windows.net/storagelevel/Spies (4).png"
                alt="Descended Logo"
                className="h-16 w-auto"
              />
              <span className="text-2xl font-serif text-sepia-50"></span>
            </div>
            <div className="flex items-center space-x-4">
              <button
                onClick={navigateToSignIn}
                className="px-4 py-2 text-sm font-medium text-sepia-50 border border-sepia-50/30 rounded-md hover:bg-sepia-50/10 transition-colors"
              >
                Sign In
              </button>
              <button
                onClick={navigateToSignUp}
                className="px-4 py-2 text-sm font-medium bg-sepia-50 text-sepia-900 rounded-md hover:bg-sepia-100 transition-colors"
              >
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* ============ HERO SECTION ============ */}
      <div className="relative h-screen">
        <div className="absolute inset-0 grid grid-cols-3 gap-1">
          <div className="relative">
            <img
              src="https://images.unsplash.com/photo-1514894780887-121968d00567"
              className="h-full w-full object-cover filter sepia"
              alt="Vintage family"
            />
            <div className="absolute inset-0 bg-sepia-900/40" />
          </div>
          <div className="relative">
            <img
              src="https://images.unsplash.com/photo-1552249007-6759fe2742b6"
              className="h-full w-full object-cover"
              alt="Modern family"
            />
            <div className="absolute inset-0 bg-sepia-900/40" />
          </div>
          <div className="relative">
            <img
              src="https://images.unsplash.com/photo-1509924603848-aca5e5f276d7"
              className="h-full w-full object-cover filter sepia"
              alt="Heritage"
            />
            <div className="absolute inset-0 bg-sepia-900/40" />
          </div>
        </div>

        <div className="absolute inset-0 bg-gradient-to-b from-sepia-900/70 via-sepia-900/50 to-sepia-900/70" />

        <div className="relative h-full flex items-center">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
            <div className="max-w-3xl mx-auto text-center sm:text-left">
              {" "}
              {/* Added mx-auto and text alignment */}
              <h1 className="text-5xl md:text-6xl font-bold tracking-tight text-sepia-50 font-serif leading-tight">
                Your Family's Story Deserves to Be Preserved
              </h1>
              <p className="mt-6 text-xl text-sepia-100 leading-relaxed">
                Every family has a unique story. Capture the memories,
                traditions, and moments that make your family special. Pass down
                your legacy for generations to come.
              </p>
              <div className="mt-10 flex items-center justify-center sm:justify-start">
                {" "}
                {/* Added justify-center */}
                <button
                  onClick={navigateToSignUp}
                  className="px-8 py-3 bg-sepia-50 text-sepia-900 rounded-md hover:bg-sepia-100 transition-colors font-medium flex items-center group"
                >
                  Start Your Family's Journey
                  <ChevronRight className="ml-2 w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ============ FEATURES SECTION ============ */}
      <div className="bg-sepia-50 py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-sepia-900 font-serif">
              Preserve Your Legacy
            </h2>
            <p className="mt-4 text-lg text-sepia-700">
              Everything you need to document and share your family's journey
            </p>
          </div>
          <div className="mt-20 grid grid-cols-1 gap-12 md:grid-cols-3">
            <div className="relative">
              <div className="absolute -top-10 left-1/2 -translate-x-1/2 w-20 h-20 bg-sepia-100 rounded-full flex items-center justify-center">
                <BookOpen className="w-10 h-10 text-sepia-700" />
              </div>
              <div className="pt-12 text-center">
                <h3 className="text-xl font-semibold text-sepia-900 mb-4">
                  Share Stories
                </h3>
                <p className="text-sepia-700">
                  Document cherished memories, family recipes, and traditions in
                  a beautiful digital format.
                </p>
              </div>
            </div>
            <div className="relative">
              <div className="absolute -top-10 left-1/2 -translate-x-1/2 w-20 h-20 bg-sepia-100 rounded-full flex items-center justify-center">
                <Users className="w-10 h-10 text-sepia-700" />
              </div>
              <div className="pt-12 text-center">
                <h3 className="text-xl font-semibold text-sepia-900 mb-4">
                  Connect Family
                </h3>
                <p className="text-sepia-700">
                  Bring your family together to collaborate and contribute their
                  own memories and stories.
                </p>
              </div>
            </div>
            <div className="relative">
              <div className="absolute -top-10 left-1/2 -translate-x-1/2 w-20 h-20 bg-sepia-100 rounded-full flex items-center justify-center">
                <Shield className="w-10 h-10 text-sepia-700" />
              </div>
              <div className="pt-12 text-center">
                <h3 className="text-xl font-semibold text-sepia-900 mb-4">
                  Secure Legacy
                </h3>
                <p className="text-sepia-700">
                  Keep your family's history safe and accessible for future
                  generations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ============ GALLERY SECTION ============ */}
      <div className="bg-sepia-900 py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-sepia-50 font-serif">
              From Past to Present
            </h2>
            <p className="mt-4 text-lg text-sepia-200">
              Every photo tells a story. Every story builds your legacy.
            </p>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <img
              src="https://images.unsplash.com/photo-1609220136736-443140cffec6"
              className="w-full h-48 object-cover rounded-lg filter sepia"
              alt="Heritage"
            />
            <img
              src="https://images.unsplash.com/photo-1511895426328-dc8714191300"
              className="w-full h-48 object-cover rounded-lg"
              alt="Modern family"
            />
            <img
              src="https://images.unsplash.com/photo-1533659828870-95ee305cee3e"
              className="w-full h-48 object-cover rounded-lg filter sepia"
              alt="Vintage"
            />
            <img
              src="https://images.unsplash.com/photo-1542037104857-ffbb0b9155fb"
              className="w-full h-48 object-cover rounded-lg"
              alt="Contemporary"
            />
          </div>
          <div className="mt-12 text-center">
            <button
              onClick={navigateToSignUp}
              className="px-8 py-3 bg-sepia-50 text-sepia-900 rounded-md hover:bg-sepia-100 transition-colors font-medium inline-flex items-center group"
            >
              Create Your Family Legacy
              <ChevronRight className="ml-2 w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
            </button>
          </div>
        </div>
      </div>

      {/* ============ FOOTER ============ */}
      <footer className="bg-sepia-900 border-t border-sepia-800 py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-center space-x-2">
            <img
              src="https://pregs.blob.core.windows.net/storagelevel/Spies (4).png"
              alt="Descended Logo"
              className="h-12 w-auto"
            />
            <span className="text-lg font-serif text-sepia-400"></span>
          </div>
          <div className="mt-4 text-center text-sm text-sepia-400">
            © {new Date().getFullYear()} Descended. All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  );
}
