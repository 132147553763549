import { useState } from "react";
import { createPortal } from "react-dom";
import { ChevronDown, PlusCircle, Loader2, Users } from "lucide-react";
import { useFamily } from "../contexts/FamilyContext";
import { supabase } from "../lib/supabase";
import { useProfile } from "../contexts/ProfileContext";

export default function FamilySwitcher() {
  const {
    families,
    currentFamily,
    setCurrentFamily,
    loading,
    showFamilyCreate,
    setShowFamilyCreate,
    refreshFamilies,
  } = useFamily();
  const { profile } = useProfile();
  const [newFamilyName, setNewFamilyName] = useState("");
  const [newFamilyDescription, setNewFamilyDescription] = useState("");
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleCreateFamily = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!profile) return;

    setCreating(true);
    setError(null);

    try {
      const { data: family, error: familyError } = await supabase
        .from("families")
        .insert({
          name: newFamilyName,
          description: newFamilyDescription,
        })
        .select()
        .single();

      if (familyError) throw familyError;

      setNewFamilyName("");
      setNewFamilyDescription("");
      setShowFamilyCreate(false);

      if (family) {
        await refreshFamilies(); // Add this
        setCurrentFamily(family);
      }
    } catch (err: any) {
      console.error("Error creating family:", err);
      setError(err.message);
    } finally {
      setCreating(false);
    }
  };

  if (loading) {
    return (
      <div className="mb-6">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center text-sepia-800">
            <Users className="w-5 h-5 mr-2" />
            <div className="h-6 w-32 bg-sepia-200 animate-pulse rounded"></div>
          </div>
          <div className="h-8 w-24 bg-sepia-200 animate-pulse rounded-md"></div>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="h-24 bg-sepia-100 animate-pulse rounded-md"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex-1 overflow-hidden flex flex-col">
        {/* Current Family Display */}
        <div className="flex items-center justify-between mb-4 px-6 py-2">
          <div className="flex items-center text-sepia-800 min-w-0">
            <Users className="w-5 h-5 mr-2 flex-shrink-0" />
            <span className="font-medium truncate">
              {currentFamily?.name || "Select Family"}
            </span>
          </div>
          <button
            onClick={() => setShowFamilyCreate(!showFamilyCreate)}
            className="flex items-center px-3 py-1.5 text-sm bg-sepia-100 text-sepia-700 rounded-md hover:bg-sepia-200 transition-colors ml-4 flex-shrink-0"
          >
            <PlusCircle className="w-4 h-4 mr-1.5" />
            New Family
          </button>
        </div>

        {/* Family List */}
        <div className="flex-1 overflow-y-auto px-6">
          {families.length > 0 ? (
            <div className="bg-white rounded-lg shadow-md overflow-hidden">
              {families.map((family, index) => (
                <button
                  key={family.id}
                  onClick={() => setCurrentFamily(family)}
                  className={`w-full text-left px-4 py-2.5 flex items-center ${
                    family.id === currentFamily?.id
                      ? "bg-sepia-50 text-sepia-900"
                      : "text-sepia-700 hover:bg-sepia-50"
                  } ${index !== 0 ? "border-t border-sepia-100" : ""}`}
                >
                  <Users
                    className={`w-4 h-4 mr-2 ${
                      family.id === currentFamily?.id
                        ? "text-sepia-900"
                        : "text-sepia-500"
                    }`}
                  />
                  <span className="flex-1 truncate">{family.name}</span>
                  {family.id === currentFamily?.id && (
                    <span className="text-xs bg-sepia-200 text-sepia-800 px-2 py-0.5 rounded-full ml-2">
                      Current
                    </span>
                  )}
                </button>
              ))}
            </div>
          ) : (
            !showFamilyCreate && (
              <div className="bg-white rounded-lg shadow-md p-6 text-center">
                <Users className="w-12 h-12 text-sepia-300 mx-auto mb-3" />
                <h3 className="text-lg font-medium text-sepia-800 mb-2">
                  No Families Yet
                </h3>
                <p className="text-sm text-sepia-600 mb-4">
                  Create your first family to start preserving your legacy.
                </p>
                <button
                  onClick={() => setShowFamilyCreate(true)}
                  className="inline-flex items-center px-4 py-2 text-sm bg-sepia-700 text-sepia-50 rounded-md hover:bg-sepia-800"
                >
                  <PlusCircle className="w-4 h-4 mr-1.5" />
                  Create Family
                </button>
              </div>
            )
          )}
        </div>
      </div>

      {/* Create Family Modal - Using Portal */}
      {showFamilyCreate &&
        createPortal(
          <div className="fixed inset-0 bg-sepia-900/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
              <div className="p-6">
                <h3 className="text-lg font-semibold text-sepia-800 mb-4">
                  Create New Family
                </h3>
                <form onSubmit={handleCreateFamily} className="space-y-4">
                  {error && (
                    <div className="p-3 text-sm bg-red-50 border border-red-200 rounded text-red-600">
                      {error}
                    </div>
                  )}
                  <div>
                    <label
                      htmlFor="familyName"
                      className="block text-sm font-medium text-sepia-700 mb-1"
                    >
                      Family Name
                    </label>
                    <input
                      id="familyName"
                      type="text"
                      value={newFamilyName}
                      onChange={(e) => setNewFamilyName(e.target.value)}
                      placeholder="Enter family name"
                      className="w-full px-3 py-2 text-sm border border-sepia-200 rounded-md focus:outline-none focus:ring-2 focus:ring-sepia-500"
                      required
                      disabled={creating}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="familyDescription"
                      className="block text-sm font-medium text-sepia-700 mb-1"
                    >
                      Description (Optional)
                    </label>
                    <input
                      id="familyDescription"
                      type="text"
                      value={newFamilyDescription}
                      onChange={(e) => setNewFamilyDescription(e.target.value)}
                      placeholder="Brief description of your family"
                      className="w-full px-3 py-2 text-sm border border-sepia-200 rounded-md focus:outline-none focus:ring-2 focus:ring-sepia-500"
                      disabled={creating}
                    />
                  </div>
                  <div className="flex items-center justify-end space-x-3 pt-4 border-t border-sepia-100">
                    <button
                      type="button"
                      onClick={() => setShowFamilyCreate(false)}
                      className="px-4 py-2 text-sm text-sepia-600 hover:text-sepia-800 disabled:opacity-50"
                      disabled={creating}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={creating}
                      className="px-4 py-2 text-sm bg-sepia-700 text-sepia-50 rounded-md hover:bg-sepia-800 disabled:opacity-50 min-w-[100px] flex items-center justify-center"
                    >
                      {creating ? (
                        <>
                          <Loader2 className="w-4 h-4 animate-spin mr-1.5" />
                          Creating...
                        </>
                      ) : (
                        "Create Family"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>,
          document.body
        )}
    </>
  );
}
