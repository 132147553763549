import { createContext, useContext, useState, useEffect } from "react";
import { supabase } from "../lib/supabase";
import { useAuth } from "./AuthContext";

type Family = {
  id: string;
  name: string;
  description: string | null;
};

type FamilyContextType = {
  families: Family[];
  setFamilies: (families: Family[]) => void; // Add this line
  currentFamily: Family | null;
  setCurrentFamily: (family: Family | null) => void;
  loading: boolean;
  error: Error | null;
  showFamilyCreate: boolean;
  setShowFamilyCreate: (show: boolean) => void;
  refreshFamilies: () => Promise<void>; // Add this
};

const FamilyContext = createContext<FamilyContextType>({
  families: [],
  setFamilies: () => {}, // Add this line
  currentFamily: null,
  setCurrentFamily: () => {},
  loading: true,
  error: null,
  showFamilyCreate: false,
  setShowFamilyCreate: () => {},
  refreshFamilies: async () => {}, //
});

export function FamilyProvider({ children }: { children: React.ReactNode }) {
  const { user } = useAuth();
  const [families, setFamilies] = useState<Family[]>([]);
  const [currentFamily, setCurrentFamily] = useState<Family | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [initialized, setInitialized] = useState(false);
  const [showFamilyCreate, setShowFamilyCreate] = useState(false);

  useEffect(() => {
    if (!user) {
      setFamilies([]);
      setCurrentFamily(null);
      setLoading(false);
      setInitialized(true);
      return;
    }

    let ignore = false;

    async function loadFamilies() {
      try {
        setLoading(true);
        setError(null);

        const { data: memberships, error: membershipError } = await supabase
          .from("family_members")
          .select("family_id")
          .eq("profile_id", user.id);

        if (membershipError) throw membershipError;

        if (!memberships || memberships.length === 0) {
          if (!ignore) {
            setFamilies([]);
            setCurrentFamily(null);
            setLoading(false);
            setInitialized(true);
          }
          return;
        }

        const familyIds = memberships.map((m) => m.family_id);
        const { data: familyData, error: familyError } = await supabase
          .from("families")
          .select("*")
          .in("id", familyIds)
          .order("name");

        if (familyError) throw familyError;

        if (!ignore) {
          setFamilies(familyData || []);

          if (familyData && familyData.length > 0 && !currentFamily) {
            setCurrentFamily(familyData[0]);
          } else if (familyData && familyData.length === 0) {
            setCurrentFamily(null);
          }

          setInitialized(true);
        }
      } catch (err: any) {
        console.error(
          "Error loading families:",
          err.message || "Unknown error occurred"
        );
        if (!ignore) {
          setError(new Error(err.message || "Failed to load families"));
          setFamilies([]);
          setCurrentFamily(null);
          setInitialized(true);
        }
      } finally {
        if (!ignore) {
          setLoading(false);
        }
      }
    }

    loadFamilies();

    return () => {
      ignore = true;
    };
  }, [user]);

  if (!initialized) {
    return null;
  }

  const refreshFamilies = async () => {
    if (!user) return;

    try {
      const { data: memberships } = await supabase
        .from("family_members")
        .select("family_id")
        .eq("profile_id", user.id);

      if (memberships && memberships.length > 0) {
        const familyIds = memberships.map((m) => m.family_id);
        const { data: familyData } = await supabase
          .from("families")
          .select("*")
          .in("id", familyIds)
          .order("name");

        setFamilies(familyData || []);
      }
    } catch (err) {
      console.error("Error refreshing families:", err);
    }
  };

  return (
    <FamilyContext.Provider
      value={{
        families,
        setFamilies,
        currentFamily,
        setCurrentFamily,
        loading,
        error,
        showFamilyCreate,
        setShowFamilyCreate,
        refreshFamilies,
      }}
    >
      {children}
    </FamilyContext.Provider>
  );
}

export const useFamily = () => useContext(FamilyContext);
