import { useState, useEffect } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { supabase } from "../lib/supabase";

export default function AuthUI() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const invitationId = searchParams.get("invitation");

  // We no longer call handleInvitation directly in the UI
  // We only call signUpAndInvite or signInAndInvite
  const { signUp, signIn, signUpAndInvite, signInAndInvite } = useAuth();

  useEffect(() => {
    // If there's an invitation or route-level state says isSignUp, default to sign-up mode
    const state = location.state as { isSignUp?: boolean } | null;
    if (invitationId || state?.isSignUp) {
      setIsSignUp(true);
    }

    // If there's an invitation, try to load the invitation email
    if (invitationId) {
      loadInvitationEmail(invitationId);
    }
  }, [location, invitationId]);

  async function loadInvitationEmail(id: string) {
    try {
      const { data, error } = await supabase
        .from("invitations")
        .select("email, expires_at")
        .eq("id", id)
        .eq("status", "pending")
        .single();
      if (error || !data) {
        setError("Invalid invitation");
        return;
      }
      if (new Date(data.expires_at) < new Date()) {
        setError("This invitation has expired");
        return;
      }
      setEmail(data.email);
    } catch (err) {
      console.error("Error loading invitation:", err);
      setError(err instanceof Error ? err.message : "Error loading invitation");
    }
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      if (invitationId) {
        // We do a "try sign-in first" approach: if sign-in fails with invalid, do sign-up

        // 1) signIn
        const { error: signInError } = await signInAndInvite(
          email,
          password,
          invitationId
        );
        if (signInError) {
          // If sign-in fails specifically with invalid / credentials / not found
          if (
            signInError.message.toLowerCase().includes("invalid") ||
            signInError.message.toLowerCase().includes("credentials") ||
            signInError.message.toLowerCase().includes("not found")
          ) {
            // 2) signUp
            const { error: signUpError } = await signUpAndInvite(
              email,
              password,
              invitationId
            );
            if (signUpError) {
              throw signUpError;
            }
          } else {
            throw signInError;
          }
        }
      } else {
        // Normal flow: no invitation
        if (isSignUp) {
          const { error: signUpError } = await signUp(email, password);
          if (signUpError) throw signUpError;
        } else {
          const { error: signInError } = await signIn(email, password);
          if (signInError) throw signInError;
        }
      }

      // On success, navigate
      navigate("/dashboard");
    } catch (err) {
      console.error("Auth error:", err);
      setError(err instanceof Error ? err.message : "Unknown error occurred");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="max-w-md w-full mx-auto p-8 bg-sepia-50/95 backdrop-blur-sm rounded-lg shadow-xl">
      <h2 className="text-2xl font-serif font-bold text-sepia-900 mb-6 text-center">
        {invitationId
          ? "Accept Family Invitation"
          : isSignUp
          ? "Create an Account"
          : "Welcome Back"}
      </h2>

      {error && (
        <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-sepia-700"
          >
            Email
          </label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={!!invitationId}
            className="mt-1 block w-full px-3 py-2 bg-white border border-sepia-300 rounded-md text-sm shadow-sm placeholder-sepia-400 focus:outline-none focus:border-sepia-500 focus:ring-1 focus:ring-sepia-500 disabled:bg-sepia-50 disabled:cursor-not-allowed"
            required
          />
        </div>

        <div>
          <label
            htmlFor="password"
            className="block text-sm font-medium text-sepia-700"
          >
            Password
          </label>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="mt-1 block w-full px-3 py-2 bg-white border border-sepia-300 rounded-md text-sm shadow-sm placeholder-sepia-400 focus:outline-none focus:border-sepia-500 focus:ring-1 focus:ring-sepia-500"
            required
          />
        </div>

        <button
          type="submit"
          disabled={loading}
          className="w-full px-4 py-2 bg-sepia-700 text-sepia-50 rounded-md hover:bg-sepia-800 focus:outline-none focus:ring-2 focus:ring-sepia-500 focus:ring-offset-2 disabled:opacity-50"
        >
          {loading
            ? "Please wait..."
            : invitationId
            ? "Join Family"
            : isSignUp
            ? "Sign Up"
            : "Sign In"}
        </button>
      </form>

      {!invitationId && (
        <div className="mt-4 text-center">
          <button
            onClick={() => setIsSignUp(!isSignUp)}
            className="text-sm text-sepia-700 hover:text-sepia-800"
          >
            {isSignUp
              ? "Already have an account? Sign in"
              : "Don't have an account? Sign up"}
          </button>
        </div>
      )}
    </div>
  );
}
