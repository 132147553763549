/* eslint-disable react-refresh/only-export-components */
import { createContext, useContext, useEffect, useState } from "react";
import { User, AuthError } from "@supabase/supabase-js";
import { PostgrestError } from "@supabase/supabase-js";
import { supabase } from "../lib/supabase";

type AuthResponse = {
  error: AuthError | PostgrestError | Error | null;
};

type AuthContextType = {
  user: User | null;
  loading: boolean;
  signUp: (email: string, password: string) => Promise<AuthResponse>;
  signIn: (email: string, password: string) => Promise<AuthResponse>;
  /**
   * signUp + handle invitation, in one go
   * (only if you want a single call from the UI)
   */
  signUpAndInvite: (
    email: string,
    password: string,
    invitationId: string
  ) => Promise<AuthResponse>;
  /**
   * signIn + handle invitation, in one go
   * (only if you want a single call from the UI)
   */
  signInAndInvite: (
    email: string,
    password: string,
    invitationId: string
  ) => Promise<AuthResponse>;

  signOut: () => Promise<void>;
};

/**
 * Internal helper function to accept the invitation (once user is set)
 */
async function acceptInvitation(
  user: User,
  invitationId: string
): Promise<AuthResponse> {
  try {
    // 1) fetch the invitation
    const { data: invitation, error: inviteError } = await supabase
      .from("invitations")
      .select("id, email, status, family_id")
      .eq("id", invitationId)
      .eq("status", "pending") // Only get pending invitations
      .single();

    if (inviteError || !invitation) {
      return { error: new Error("Invalid invitation") };
    }

    // 2) Update invitation status first
    const { error: updateError } = await supabase
      .from("invitations")
      .update({ status: "accepted" })
      .eq("id", invitationId)
      .eq("status", "pending"); // Only update if still pending

    if (updateError) {
      return { error: updateError };
    }

    // 3) Try to add user to family, ignore if already exists
    const { error: addError } = await supabase.from("family_members").upsert(
      {
        family_id: invitation.family_id,
        profile_id: user.id,
        role: "member",
      },
      {
        onConflict: "family_id,profile_id",
        ignoreDuplicates: true,
      }
    );

    if (addError) {
      // Only return error if it's not a duplicate error
      if (addError.code !== "23505") {
        return { error: addError };
      }
    }

    return { error: null };
  } catch (err) {
    return { error: err as Error };
  }
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  loading: true,
  signUp: async () => ({ error: null }),
  signIn: async () => ({ error: null }),
  signUpAndInvite: async () => ({ error: null }),
  signInAndInvite: async () => ({ error: null }),
  signOut: async () => {},
});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // On mount, fetch current session
    supabase.auth.getSession().then(({ data: { session } }) => {
      setUser(session?.user ?? null);
      setLoading(false);
    });

    // Listen for future auth changes
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user ?? null);
    });

    return () => subscription.unsubscribe();
  }, []);

  // ─────────────────────────────────────────────────────────────────────────────
  // SIGN UP
  // ─────────────────────────────────────────────────────────────────────────────
  const signUp = async (
    email: string,
    password: string
  ): Promise<AuthResponse> => {
    try {
      // 1) Attempt signUp
      const { data: authData, error: signUpError } = await supabase.auth.signUp(
        {
          email,
          password,
        }
      );
      if (signUpError) throw signUpError;
      if (!authData.user) throw new Error("No user data returned from signUp");

      // 2) If Supabase did NOT give a session, force signIn
      let activeUser = authData.user;
      if (!authData.session) {
        const { data: forcedSignIn, error: forcedSignInError } =
          await supabase.auth.signInWithPassword({ email, password });
        if (forcedSignInError) throw forcedSignInError;
        if (!forcedSignIn?.user) {
          throw new Error("No user returned after forced signIn");
        }
        activeUser = forcedSignIn.user;
      }

      // 3) Create the user’s profile if needed
      const { error: profileError } = await supabase
        .from("profiles")
        .insert({
          id: activeUser.id,
          full_name: email.split("@")[0],
          avatar_url: null,
          bio: null,
        })
        .select()
        .single();

      if (profileError) {
        // If profile creation fails, remove the new user from auth
        await supabase.auth.admin.deleteUser(activeUser.id);
        throw profileError;
      }

      // 4) Put the user in state
      setUser(activeUser);

      return { error: null };
    } catch (error) {
      console.error("Signup error:", error);
      return { error: error as AuthError | PostgrestError | Error };
    }
  };

  // ─────────────────────────────────────────────────────────────────────────────
  // SIGN IN
  // ─────────────────────────────────────────────────────────────────────────────
  const signIn = async (
    email: string,
    password: string
  ): Promise<AuthResponse> => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) throw error;
      if (!data.user) throw new Error("No user returned from signIn");

      // Ensure there's at least a minimal profile row
      const { data: profile, error: profileError } = await supabase
        .from("profiles")
        .select("*")
        .eq("id", data.user.id)
        .single();

      if (profileError || !profile) {
        // Create profile if missing
        const { error: createError } = await supabase
          .from("profiles")
          .insert({
            id: data.user.id,
            full_name: email.split("@")[0],
            avatar_url: null,
            bio: null,
          })
          .select()
          .single();
        if (createError) throw createError;
      }

      // Immediately set user in state
      setUser(data.user);

      return { error: null };
    } catch (error) {
      console.error("Sign in error:", error);
      return { error: error as AuthError | PostgrestError | Error };
    }
  };

  // ─────────────────────────────────────────────────────────────────────────────
  // SIGN UP + INVITE
  // ─────────────────────────────────────────────────────────────────────────────
  const signUpAndInvite = async (
    email: string,
    password: string,
    invitationId: string
  ): Promise<AuthResponse> => {
    // 1) signUp
    const signUpRes = await signUp(email, password);
    if (signUpRes.error) return signUpRes;

    // Get the user directly from the signup response instead of context
    const { data } = await supabase.auth.getSession();
    const newUser = data.session?.user;

    if (!newUser) return { error: new Error("No user found after signup") };

    // Use the newUser instead of depending on context
    return await acceptInvitation(newUser, invitationId);
  };

  const signInAndInvite = async (
    email: string,
    password: string,
    invitationId: string
  ): Promise<AuthResponse> => {
    // 1) signIn
    const signInRes = await signIn(email, password);
    if (signInRes.error) return signInRes;

    // Get the user directly from auth instead of context
    const { data } = await supabase.auth.getSession();
    const newUser = data.session?.user;

    if (!newUser) return { error: new Error("No user found after signin") };

    // Use the newUser instead of depending on context
    return await acceptInvitation(newUser, invitationId);
  };

  // ─────────────────────────────────────────────────────────────────────────────
  // SIGN OUT
  // ─────────────────────────────────────────────────────────────────────────────
  const signOut = async () => {
    await supabase.auth.signOut();
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        signUp,
        signIn,
        signUpAndInvite,
        signInAndInvite,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
