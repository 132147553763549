import { ScrollText, Calendar, User, Image as ImageIcon, Folder } from 'lucide-react';

type Story = {
  id: string;
  title: string;
  content: string;
  images: string[];
  date_of_event: string | null;
  created_at: string;
  author: {
    full_name: string;
  } | null;
  collection: {
    name: string;
  } | null;
};

type StoryCardProps = {
  story: Story;
  onClick?: () => void;
};

export default function StoryCard({ story, onClick }: StoryCardProps) {
  // Strip HTML tags for preview
  const contentPreview = story.content
    .replace(/<[^>]*>/g, '')
    .slice(0, 150) + (story.content.length > 150 ? '...' : '');

  return (
    <div 
      className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-200 cursor-pointer"
      onClick={onClick}
    >
      <div className="aspect-[16/9] bg-sepia-100 flex items-center justify-center relative">
        {story.images?.[0] ? (
          <img 
            src={story.images[0]} 
            alt={story.title}
            className="w-full h-full object-cover"
          />
        ) : (
          <ScrollText className="w-12 h-12 text-sepia-400" />
        )}
        {story.images?.length > 1 && (
          <div className="absolute bottom-2 right-2 bg-sepia-900/75 text-sepia-50 px-2 py-1 rounded text-xs font-medium">
            +{story.images.length - 1} more
          </div>
        )}
      </div>
      <div className="p-6">
        <h3 className="font-serif text-xl font-semibold text-sepia-900 mb-2">
          {story.title}
        </h3>
        <p className="text-sm text-sepia-600 mb-4 line-clamp-3">
          {contentPreview}
        </p>
        <div className="flex flex-col space-y-2 text-sm text-sepia-500">
          {story.collection && (
            <div className="flex items-center">
              <Folder className="w-4 h-4 mr-1" />
              <span>{story.collection.name}</span>
            </div>
          )}
          <div className="flex items-center space-x-4">
            {story.date_of_event && (
              <div className="flex items-center">
                <Calendar className="w-4 h-4 mr-1" />
                <span>{new Date(story.date_of_event).toLocaleDateString()}</span>
              </div>
            )}
            <div className="flex items-center">
              <User className="w-4 h-4 mr-1" />
              <span>{story.author?.full_name || 'Unknown'}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}