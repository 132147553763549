import React from 'react';
import { X, User } from 'lucide-react';

type MemberBioModalProps = {
  member: {
    profile: {
      id: string;
      full_name: string;
      avatar_url: string | null;
      bio: string | null;
    };
    role: string;
  };
  onClose: () => void;
};

export default function MemberBioModal({ member, onClose }: MemberBioModalProps) {
  return (
    <div className="fixed inset-0 bg-sepia-900/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-sepia-50 rounded-lg shadow-xl w-full max-w-lg">
        <div className="p-6 border-b border-sepia-200 flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <div className="w-12 h-12 bg-sepia-200 rounded-full flex items-center justify-center overflow-hidden">
              {member.profile.avatar_url ? (
                <img
                  src={member.profile.avatar_url}
                  alt={member.profile.full_name}
                  className="w-full h-full object-cover"
                />
              ) : (
                <User className="w-6 h-6 text-sepia-600" />
              )}
            </div>
            <div>
              <h2 className="text-xl font-serif font-bold text-sepia-900">
                {member.profile.full_name}
              </h2>
              <p className="text-sm text-sepia-600 capitalize">{member.role}</p>
            </div>
          </div>
          <button
            onClick={onClose}
            className="text-sepia-500 hover:text-sepia-700"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="p-6">
          <h3 className="text-sm font-medium text-sepia-700 mb-2">Bio</h3>
          <p className="text-sepia-800">
            {member.profile.bio || 'No bio available'}
          </p>
        </div>
      </div>
    </div>
  );
}