// ========================= IMPORTS =========================
import React, { useState, useEffect } from "react";
import {
  useNavigate,
  Route,
  Routes,
  Link,
  useLocation,
  Navigate,
} from "react-router-dom";
import {
  ScrollText,
  Users,
  Settings,
  LogOut,
  PlusCircle,
  Loader2,
  Menu,
  X,
} from "lucide-react";
import { supabase } from "../lib/supabase";
import { useAuth } from "../contexts/AuthContext";
import { useProfile } from "../contexts/ProfileContext";
import { useFamily } from "../contexts/FamilyContext";
import StoryEditor from "../components/StoryEditor";
import StoryCard from "../components/StoryCard";
import StoryViewer from "../components/StoryViewer";
import FamilyMembersView from "../components/FamilyMembersView";
import SettingsView from "../components/SettingsView";
import CollectionSelector from "../components/CollectionSelector";
import FamilySwitcher from "../components/FamilySwitcher";

// ========================= STORIESVIEW COMPONENT =========================
function StoriesView() {
  const { profile } = useProfile();
  const { currentFamily, showFamilyCreate, setShowFamilyCreate } = useFamily();
  const [showEditor, setShowEditor] = useState(false);
  const [editingStory, setEditingStory] = useState(null);
  const [selectedStory, setSelectedStory] = useState(null);
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCollection, setSelectedCollection] = useState(() => {
    const saved = localStorage.getItem("descended_selected_collection");
    return saved ? JSON.parse(saved) : null;
  });

  useEffect(() => {
    if (currentFamily) {
      loadStories();
    }
  }, [currentFamily, selectedCollection]);

  useEffect(() => {
    if (selectedCollection === null) {
      localStorage.removeItem("descended_selected_collection");
    } else {
      localStorage.setItem(
        "descended_selected_collection",
        JSON.stringify(selectedCollection)
      );
    }
  }, [selectedCollection]);

  const loadStories = async () => {
    if (!currentFamily) return;
    try {
      setLoading(true);
      let query = supabase
        .from("stories")
        .select(
          `*, author:profiles(id, full_name), collection:story_collections(name)`
        )
        .eq("family_id", currentFamily.id);
      if (selectedCollection) {
        query = query.eq("collection_id", selectedCollection);
      }
      const { data, error } = await query.order("created_at", {
        ascending: false,
      });
      if (error) throw error;
      setStories(data || []);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEditStory = (story) => {
    setEditingStory(story);
    setShowEditor(true);
    setSelectedStory(null);
  };

  const handleDeleteStory = async () => {
    if (!selectedStory) return;
    await loadStories();
    setSelectedStory(null);
  };

  if (!currentFamily) {
    return (
      <div>
        <div className="text-center py-8">
          <h2 className="text-xl font-semibold text-sepia-800 mb-4">
            Welcome to Descended
          </h2>
          <p className="text-sepia-600">
            Create or join a family to start sharing stories.
          </p>
        </div>

        {/* Now uses the shared context function */}
        <div className="block lg:hidden mt-8">
          <div className="bg-white rounded-lg shadow-md p-6 text-center mx-4">
            <Users className="w-12 h-12 text-sepia-300 mx-auto mb-3" />
            <h3 className="text-lg font-medium text-sepia-800 mb-2">
              No Families Yet
            </h3>
            <p className="text-sm text-sepia-600 mb-4">
              Create your first family to start preserving your legacy.
            </p>
            <button
              onClick={() => setShowFamilyCreate(true)}
              className="inline-flex items-center px-4 py-2 text-sm bg-sepia-700 text-sepia-50 rounded-md hover:bg-sepia-800"
            >
              <PlusCircle className="w-4 h-4 mr-1.5" />
              Create Family
            </button>
          </div>
        </div>
      </div>
    );
  }
  if (!currentFamily) {
    return (
      <div>
        <div className="text-center py-8">
          <h2 className="text-xl font-semibold text-sepia-800 mb-4">
            Welcome to Descended
          </h2>
          <p className="text-sepia-600">
            Create or join a family to start sharing stories.
          </p>
        </div>

        {/* Now uses the shared context function */}
        <div className="block lg:hidden mt-8">
          <div className="bg-white rounded-lg shadow-md p-6 text-center mx-4">
            <Users className="w-12 h-12 text-sepia-300 mx-auto mb-3" />
            <h3 className="text-lg font-medium text-sepia-800 mb-2">
              No Families Yet
            </h3>
            <p className="text-sm text-sepia-600 mb-4">
              Create your first family to start preserving your legacy.
            </p>
            <button
              onClick={() => setShowFamilyCreate(true)}
              className="inline-flex items-center px-4 py-2 text-sm bg-sepia-700 text-sepia-50 rounded-md hover:bg-sepia-800"
            >
              <PlusCircle className="w-4 h-4 mr-1.5" />
              Create Family
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-sepia-600" />
      </div>
    );
  }

  return (
    <div>
      <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 mb-6">
        <h1 className="text-3xl font-serif font-bold text-sepia-900">
          Family Stories
        </h1>
        <button
          onClick={() => {
            setEditingStory(null);
            setShowEditor(true);
          }}
          className="flex items-center px-4 py-2 bg-sepia-700 text-sepia-50 rounded-md hover:bg-sepia-800 w-full sm:w-auto justify-center"
        >
          <PlusCircle className="w-5 h-5 mr-2" />
          New Story
        </button>
      </div>

      {error && (
        <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-md text-red-700">
          {error}
        </div>
      )}

      {currentFamily && (
        <CollectionSelector
          familyId={currentFamily.id}
          selectedCollection={selectedCollection}
          onCollectionChange={setSelectedCollection}
        />
      )}

      {stories.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {stories.map((story) => (
            <StoryCard
              key={story.id}
              story={story}
              onClick={() => setSelectedStory(story)}
            />
          ))}
        </div>
      ) : (
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          <div className="h-48 bg-sepia-200 flex items-center justify-center">
            <ScrollText className="w-12 h-12 text-sepia-400" />
          </div>
          <div className="p-4">
            <h3 className="font-serif text-lg font-semibold text-sepia-900">
              Create Your First Story
            </h3>
            <p className="mt-2 text-sm text-sepia-600">
              Share your family's memories and preserve them for generations to
              come.
            </p>
            <div className="mt-4 flex items-center text-sm text-sepia-500">
              <span>Click "New Story" to begin</span>
            </div>
          </div>
        </div>
      )}

      {showEditor && currentFamily && (
        <StoryEditor
          familyId={currentFamily.id}
          story={editingStory}
          onClose={() => {
            setShowEditor(false);
            setEditingStory(null);
          }}
          onSuccess={() => {
            setShowEditor(false);
            setEditingStory(null);
            loadStories();
          }}
        />
      )}

      {selectedStory && (
        <StoryViewer
          story={selectedStory}
          onClose={() => setSelectedStory(null)}
          onEdit={() => handleEditStory(selectedStory)}
          onDelete={handleDeleteStory}
        />
      )}
    </div>
  );
}

// ========================= DASHBOARDPAGE COMPONENT =========================
export default function DashboardPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { signOut } = useAuth();
  const { profile, loading: profileLoading } = useProfile();
  const { loading: familyLoading } = useFamily();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const isActive = (path) => location.pathname === `/dashboard${path}`;

  if (profileLoading || familyLoading) {
    return (
      <div className="min-h-screen bg-sepia-50 flex items-center justify-center">
        <Loader2 className="w-8 h-8 animate-spin text-sepia-600" />
      </div>
    );
  }

  if (!profile) {
    return <Navigate to="/auth" replace />;
  }

  // ========================= MOBILE HEADER =========================
  return (
    <div className="min-h-screen bg-sepia-50">
      <div className="lg:hidden bg-white shadow-md p-4 sticky top-0 z-40">
        <div className="flex items-center justify-between">
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="p-2 text-sepia-600 hover:text-sepia-800"
          >
            {isMobileMenuOpen ? (
              <X className="h-6 w-6" />
            ) : (
              <Menu className="h-6 w-6" />
            )}
          </button>
          <div className="flex items-center justify-center">
            <img
              src="https://pregs.blob.core.windows.net/storagelevel/Spies (3).png"
              alt="Descended Logo"
              className="h-12 w-auto"
            />
          </div>
        </div>
      </div>

      {/* ========================= LAYOUT WRAPPER ========================= */}
      <div className="flex h-[calc(100vh-64px)] lg:h-screen">
        {/* ========================= SIDEBAR ========================= */}
        <div
          className={`${
            isMobileMenuOpen ? "block" : "hidden"
          } fixed inset-0 lg:relative lg:block z-30`}
        >
          <div
            className="fixed inset-0 bg-sepia-900/50 lg:hidden"
            onClick={() => setIsMobileMenuOpen(false)}
          />
          <div className="w-64 bg-white shadow-md h-full flex flex-col relative z-40">
            <div className="flex-1 overflow-y-auto">
              <div className="p-6">
                <div className="hidden lg:flex flex-col items-center mb-6">
                  <div className="flex items-center space-x-1">
                    <img
                      src="https://pregs.blob.core.windows.net/storagelevel/Spies (3).png"
                      alt="Descended Logo"
                      className="h-16 w-auto"
                    />
                    <span className="text-2xl font-serif text-sepia-900"></span>
                  </div>
                  <hr className="w-full mt-2 border-sepia-200" />
                </div>
                <div className="mb-6">
                  <h2 className="text-lg font-serif font-semibold text-sepia-900">
                    {profile.full_name}
                  </h2>
                  <p className="text-sm text-sepia-600">Family Member</p>
                </div>
                <FamilySwitcher />
                <nav className="space-y-1 mt-6">
                  <Link
                    to="/dashboard"
                    onClick={() => setIsMobileMenuOpen(false)}
                    className={`flex items-center px-4 py-2 rounded-md ${
                      isActive("")
                        ? "text-sepia-900 bg-sepia-50"
                        : "text-sepia-600 hover:bg-sepia-50"
                    }`}
                  >
                    <ScrollText className="w-5 h-5 mr-3" />
                    Stories
                  </Link>
                  <Link
                    to="/dashboard/members"
                    onClick={() => setIsMobileMenuOpen(false)}
                    className={`flex items-center px-4 py-2 rounded-md ${
                      isActive("/members")
                        ? "text-sepia-900 bg-sepia-50"
                        : "text-sepia-600 hover:bg-sepia-50"
                    }`}
                  >
                    <Users className="w-5 h-5 mr-3" />
                    Family Members
                  </Link>
                  <Link
                    to="/dashboard/settings"
                    onClick={() => setIsMobileMenuOpen(false)}
                    className={`flex items-center px-4 py-2 rounded-md ${
                      isActive("/settings")
                        ? "text-sepia-900 bg-sepia-50"
                        : "text-sepia-600 hover:bg-sepia-50"
                    }`}
                  >
                    <Settings className="w-5 h-5 mr-3" />
                    Settings
                  </Link>
                </nav>
              </div>
            </div>
            <div className="p-4 border-t border-sepia-100 bg-white">
              <button
                onClick={async () => {
                  await signOut();
                  navigate("/");
                }}
                className="flex items-center px-4 py-2 text-sepia-600 hover:bg-sepia-50 rounded-md w-full"
              >
                <LogOut className="w-5 h-5 mr-3" />
                Sign Out
              </button>
            </div>
          </div>
        </div>

        {/* ========================= MAIN CONTENT ========================= */}
        <div className="flex-1 overflow-y-auto">
          <div className="p-4 sm:p-8">
            <Routes>
              <Route path="/" element={<StoriesView />} />
              <Route path="/members" element={<FamilyMembersView />} />
              <Route path="/settings" element={<SettingsView />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}
