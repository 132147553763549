import React, { useState, useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import { Calendar, Loader2, Image as ImageIcon, Folder, X } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useProfile } from '../contexts/ProfileContext';
import QuillEditor from './QuillEditor';

type Collection = {
  id: string;
  name: string;
};

type Story = {
  id: string;
  title: string;
  content: string;
  date_of_event: string | null;
  images: string[];
  collection_id: string | null;
};

type StoryEditorProps = {
  onClose: () => void;
  onSuccess: () => void;
  familyId: string;
  story?: Story | null;
};

export default function StoryEditor({ onClose, onSuccess, familyId, story }: StoryEditorProps) {
  const { profile } = useProfile();
  const [title, setTitle] = useState(story?.title || '');
  const [content, setContent] = useState(story?.content || '');
  const [date, setDate] = useState(story?.date_of_event || '');
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [imagePreviews, setImagePreviews] = useState<string[]>(story?.images || []);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [collections, setCollections] = useState<Collection[]>([]);
  const [selectedCollection, setSelectedCollection] = useState<string | null>(story?.collection_id || null);
  const quillRef = useRef<ReactQuill>(null);

  useEffect(() => {
    loadCollections();
  }, []);

  const loadCollections = async () => {
    const { data, error } = await supabase
      .from('story_collections')
      .select('id, name')
      .eq('family_id', familyId)
      .order('name');

    if (error) {
      console.error('Error loading collections:', error);
      return;
    }

    setCollections(data || []);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    if (files.length === 0) return;

    setImageFiles(prev => [...prev, ...files]);

    files.forEach(file => {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviews(prev => [...prev, reader.result as string]);
      };
      reader.readAsDataURL(file);
    });
  };

  const removeImage = (index: number) => {
    setImageFiles(prev => prev.filter((_, i) => i !== index));
    setImagePreviews(prev => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!profile) return;

    setSaving(true);
    setError(null);

    try {
      let imageUrls = story?.images || [];

      // Handle image uploads
      if (imageFiles.length > 0) {
        // Delete old images if editing
        if (story?.images) {
          const oldImagePaths = story.images.map(url => url.split('/').pop()).filter(Boolean);
          if (oldImagePaths.length > 0) {
            await supabase.storage
              .from('story-images')
              .remove(oldImagePaths);
          }
        }

        // Upload new images
        for (const file of imageFiles) {
          const fileExt = file.name.split('.').pop();
          const filePath = `${familyId}/${Math.random()}.${fileExt}`;

          const { error: uploadError } = await supabase.storage
            .from('story-images')
            .upload(filePath, file);

          if (uploadError) throw uploadError;

          const { data: { publicUrl } } = supabase.storage
            .from('story-images')
            .getPublicUrl(filePath);

          imageUrls.push(publicUrl);
        }
      }

      if (story) {
        // Update existing story
        const { error: updateError } = await supabase
          .from('stories')
          .update({
            title,
            content,
            date_of_event: date || null,
            images: imageUrls,
            collection_id: selectedCollection,
            updated_at: new Date().toISOString()
          })
          .eq('id', story.id);

        if (updateError) throw updateError;
      } else {
        // Create new story
        const { error: storyError } = await supabase
          .from('stories')
          .insert({
            family_id: familyId,
            author_id: profile.id,
            title,
            content,
            date_of_event: date || null,
            images: imageUrls,
            collection_id: selectedCollection
          });

        if (storyError) throw storyError;
      }

      onSuccess();
    } catch (err: any) {
      console.error('Error saving story:', err);
      setError(err.message);
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-sepia-900/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-sepia-50 rounded-lg shadow-xl w-full max-w-4xl max-h-[90vh] overflow-hidden">
        <div className="p-6 border-b border-sepia-200">
          <h2 className="text-2xl font-serif font-bold text-sepia-900">
            {story ? 'Edit Story' : 'Create New Story'}
          </h2>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6 overflow-y-auto max-h-[calc(90vh-130px)]">
          {error && (
            <div className="p-4 bg-red-50 border border-red-200 rounded-md text-red-700">
              {error}
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-sepia-700 mb-1">
              Title
            </label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full px-3 py-2 border border-sepia-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sepia-500"
              placeholder="Enter story title"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-sepia-700 mb-1">
              Collection (Optional)
            </label>
            <select
              value={selectedCollection || ''}
              onChange={(e) => setSelectedCollection(e.target.value || null)}
              className="w-full px-3 py-2 border border-sepia-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sepia-500"
            >
              <option value="">No Collection</option>
              {collections.map((collection) => (
                <option key={collection.id} value={collection.id}>
                  {collection.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-sepia-700 mb-1">
              Story Images
            </label>
            <div className="mt-1 space-y-4">
              {imagePreviews.length > 0 && (
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                  {imagePreviews.map((preview, index) => (
                    <div key={index} className="relative group">
                      <img
                        src={preview}
                        alt={`Preview ${index + 1}`}
                        className="w-full h-32 object-cover rounded-md"
                      />
                      <button
                        type="button"
                        onClick={() => removeImage(index)}
                        className="absolute top-2 right-2 p-1 bg-red-600 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                      >
                        <X className="w-4 h-4" />
                      </button>
                    </div>
                  ))}
                </div>
              )}
              <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-sepia-300 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  <ImageIcon className="mx-auto h-12 w-12 text-sepia-400" />
                  <div className="flex text-sm text-sepia-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md font-medium text-sepia-700 hover:text-sepia-800"
                    >
                      <span>Upload images</span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        accept="image/*"
                        multiple
                        className="sr-only"
                        onChange={handleImageChange}
                      />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs text-sepia-500">
                    PNG, JPG, GIF up to 10MB each
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-sepia-700 mb-1">
              Date of Event
            </label>
            <div className="relative">
              <input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                className="w-full px-3 py-2 border border-sepia-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sepia-500 pr-10"
              />
              <Calendar className="absolute right-3 top-2.5 h-5 w-5 text-sepia-400" />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-sepia-700 mb-1">
              Story Content
            </label>
            <div className="prose max-w-none">
              <QuillEditor
                ref={quillRef}
                value={content}
                onChange={setContent}
              />
            </div>
          </div>

          <div className="flex justify-end space-x-4 pt-4 border-t border-sepia-200">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-sepia-700 hover:text-sepia-800"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={saving}
              className="px-4 py-2 bg-sepia-700 text-sepia-50 rounded-md hover:bg-sepia-800 disabled:opacity-50 flex items-center"
            >
              {saving ? (
                <>
                  <Loader2 className="animate-spin -ml-1 mr-2 h-4 w-4" />
                  Saving...
                </>
              ) : (
                story ? 'Save Changes' : 'Create Story'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}